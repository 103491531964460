import React from "react";
import "./styles/spinner.scss";

export default function LoadingSpinner() {
    return (
        <div className='spinner-container'>
            <div className='loading-spinner'></div>
        </div>
    );
}
