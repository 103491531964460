import React from "react";
import styled from "styled-components";

export default function PageNotFound() {
    return (
        <PageNotFoundStyled>
            PageNotFound
        </PageNotFoundStyled>
    );
}

const PageNotFoundStyled = styled.div``;
